// Breakpoints
$large-breakpoint: 64em !default;
$medium-breakpoint: 42em !default;

// Headers
$header-heading-color: #fff !default;
$header-bg-color: #159957 !default;
$header-bg-color-secondary: #155799 !default;

// Text
$section-headings-color: #795548 !default;
$body-text-color: #46433a !default;
$body-link-color: #f09444 !default;
$blockquote-text-color: #819198 !default;

// Code
$code-bg-color: #f3f6fa !default;
$code-text-color: #567482 !default;

// Borders
$border-color: #dce6f0 !default;
$table-border-color: #e9ebec !default;
$hr-border-color: #eff0f1 !default;
