// event styles
// ---------------------------------------/

// util


// resume content styles
.event-content-section {
  margin: 0 0 3rem;
  .h2 { color: black;} 
  .resume-item { margin-bottom: 2rem; }

  .event-item-title {
    @include sans_bold;
    margin: 0 0 .75rem;
    font-size: 1.5rem;
    letter-spacing: -.05rem;
    line-height: 1;
  }

  .event-item-details {
    font-size: 1.125rem;
    margin: 0 0 .75rem;
    line-height: 1;

    &.award-title {
      font-size: 1rem;
    }
  }

  .event-item-title,
  .event-item-details,
  .event-item-list {
    a {
      color: #333;
      text-decoration: none;
      border-bottom: 1px solid;

      &:hover {
        border-bottom: none;
        color: #9c9c9c;
      }
    }
  }

  .resume-item-copy { margin: .75rem 0 0; }
}

