/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;
  background: #732307; // hb
  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.4);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  text-decoration: none;

  &,
  &:visited,
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  background: #732307 !important;

  .nav-trigger {
      display: none;

  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: #ffffff;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;
    z-index: 9999;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
/**        fill: $grey-color-dark; **/ 
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .trigger {
      padding-top: 20px;
    }
    .page-link {
      display: block;
      padding: 10px 10px;

      &:last-child {
        margin-bottom: 10px;
        margin-right: 20px;
      }
      margin-right: 10px;
      margin-left: 10px;
    }

  }
}

/**
 * Post list
 */
.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit * 1.5;
  }

  > li > h2 {
    margin-top: 0rem;
    margin-bottom: 0.4rem;
  }

  > li > p {
    margin-top: 0rem;
  }
}

@include media-query($on-palm) {
  .post-list {
    -webkit-margin-end: 10px;
    -webkit-padding-start: 10px;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}
