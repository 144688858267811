// Resume styles
// ---------------------------------------/

// util

.section-header {
  @include section_border;
  margin-bottom: 1.5rem;

  h2 {
    @include sans;
    font-weight: 900;
    font-size: 1.75rem;
    letter-spacing: -.05rem;
    margin: 0;
  }
}

// page header styles

.page-header { padding: 1.5rem 0;

  .avatar {
    width: 95px;
    max-width: 100%;
    overflow: hidden;
    @include border-radius(100px);
    margin: 0 auto;
    display: block;
  }

  .header-name {
    @include sans;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    letter-spacing: -.15rem;
    line-height: 1;
    margin: 1rem 0 0;
  }

  .header-contact-info {
    @include sans;
    text-align: center;
  }
  .header-contact-info p a {
    color: inherit;
  }

  .title-bar {
    @include section_border;
    margin-top: 2rem;
    padding: .4rem 0;
    @include clearfix;

    .header-title {
      @include sans;
      font-weight: 300;
      font-size: 1.75rem;
      line-height: 2rem;
      letter-spacing: -.05rem;
      text-align: center;
      margin: 0 0 .5rem;

      @include media_larger_than_mobile {
        float: left;
        margin: 0;
      }
    }
  }

  .executive-summary {
    @include media_larger_than_mobile { font-size: 1.125rem; }
  }
}

.icon-links {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  top: 4px;
  text-align: center;
  border-top: 2px solid #c7c7c7;
  padding-top: .4rem;

  @include media_larger_than_mobile {
    float: right;
    border-top: none;
    padding-top: 0;
  }

  .icon-link-item {
    display: inline-block;
    margin-left: 5px;
  }

  .icon-link { display: inline-block;

    &:hover .icon path {
      fill: #333;
      @include transition(all .2s ease);
    }
  }

  .icon {
    height: 28px;
  }
}

.contact-button {
  @include border-radius(3px);
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 1.5rem auto;
  width: 220px;
  font-size: 1.375rem;
  @include sans_light;
  color: #333;
  line-height: 55px;
  @include transition(all .2s ease);

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &.not-looking { width: 400px; }
}

// resume content styles
.resume-item-address {
    font-size: 1rem;
}
.resume-content-section {
  margin: 0 0 3rem;
  .h2 { color: black;} 
  .resume-item { margin-bottom: 2rem; }

  .resume-item-title {
    @include sans_bold;
    margin: 0 0 .75rem;
    font-size: 1.5rem;
    letter-spacing: -.05rem;
    line-height: 1;
  }

  .resume-item-details {
    font-size: 1.125rem;
    margin: 0 0 .75rem;
    line-height: 1;

    &.award-title {
      font-size: 1rem;
    }
  }

  .resume-item-title,
  .resume-item-details,
  .resume-item-copy,
  .resume-item-list {
    a {
      color: #333;
      text-decoration: none;
      border-bottom: 1px solid;

      &:hover {
        border-bottom: none;
        color: #9c9c9c;
      }
    }
  }

  .resume-item-copy { margin: .75rem 0 0; }
}



.print-only { display: none !important }

@media print {
  .no-print { display: none !important }
  .print-only { display: block !important }
  body { font-size: 12px; line-height: 1rem; }
  .page-header { padding: 1rem 0 .2rem;
    .header-name {
      @include sans_bold;
      font-size: 2rem;
      margin: 0 0 .2rem;
    }
  }
  .section-header {
    @include section_border_thin;
    margin-bottom: 1rem;
    h2 { font-size: 1.2rem; line-height: 1.5rem; }
  }
  .content-section {
    margin: 0 0 1rem;
    .resume-item { margin-bottom: 1rem; }
    .resume-item-title {
      font-size: 16px;
      line-height: .7em;
    }

    .resume-item-details {
      font-size: 12px;
      margin: 0 0 .75rem;
      line-height: .7em;
      font-style:italic;

      &.award-title {
        font-size: 11px;
      }
    }
  }
}


@media print
   {
    @page {
        size: A4;
    }
    body {
       font-size: 12pt;
    }

    img.avatar {
        filter: grayscale(1);
        -webkit-filter: greyscale(1);
    }
   .main-wrapper .time {
       float: right;
        color: black !important;
        -webkit-print-color-adjust: exact; 
   }
    .sidebar-wrapper {
        position: static;
        float: right;
        width: 40%;
        -webkit-print-color-adjust: exact; 
    }

    .remove-container.container-block {
       display: none;
    }

    footer {
       display: none;
    }

    .skillset .level-bar-inner {
       background-color: black !important;
       -webkit-print-color-adjust: exact; 
    }

}
